import React from "react";
import TimelineCard from "../components/TimelineCard";
import { 
    Box,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import { 
    Timeline,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@material-ui/lab";
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import MovieIcon from '@material-ui/icons/Movie';

 // CSS Styles
 const useStyles = makeStyles(theme=>({
    container: {
        height: "20%", 
        width: "100vw", 
        zIndex: "1",
        // display: "inline-block",
        padding: "0%",
        // background: "grey",
        "& .timelineContainer": {
            padding: "1rem",
            [theme.breakpoints.up("md")]: {
                marginLeft: "5rem",
                marginRight: "5rem",
            },
            "& .timelineItemDot": {
                background: "#6495ED"
            },
            "& .timelineItemDates": {
                color: "#A9A9A9", 
                paddingTop: "0.5rem", 
                fontSize: "15px",
                [theme.breakpoints.down("sm")]: {
                    fontSize: "13px",
                    paddingTop: "0.7rem"
                }
            }
        }
    },

}))

// Content

const timelineItems = [
    {
        timelineIcon: <SchoolIcon />,
        timelineName: "Carleton College",
        timelineDescription: "Bachelor of Arts - major: Cinema and Media Studies",
        timelineDate: "2014 - 2017",
        timelineExpand: `In top 10 (US News, Rankings for National Liberal Arts Colleges). <br/> <br/> Capstone Project: <i> After the Storms </i> (Film in the filmography section of the portfolio)`
    },
    {
        timelineIcon: <WorkIcon />,
        timelineName: "BNP Paribas",
        timelineDescription: "V.I.E (postgraduate intern) as a Multimedia Analyst",
        timelineDate: "2017 - 2018"
    },
    {
        timelineIcon: <SchoolIcon />,
        timelineName: "New York University School of Professional Studies",
        timelineDescription: "Diploma - Introduction to Programming with Python",
        timelineDate: "2018 - 2020",
        timelineExpand: "Note: Fully built this portfolio website using React"
    },
    {
        timelineIcon: <WorkIcon />,
        timelineName: "BNP Paribas",
        timelineDescription: "Multimedia Analyst",
        timelineDate: "2018 - 2021",
        timelineExpand: "Built a fully-functional mini production studio able to manage and produce all external and internal multimedia contents for BNP Paribas CIB USA, from the conception stages to the completion."
    },
    {
        timelineIcon: <WorkIcon />,
        timelineName: "French-American Foundation - France",
        timelineDescription: "Program Coordinator",
        timelineDate: "Sept. 2021 - Dec. 2021",
        timelineExpand: "Assisted the Program Director and Managing Director on the organisation of online and onsite events, such as Young Leader Seminar, 40-year anniversary of the Young Leader Program, The French-American Breakfasts, The Cyber Security Conference and additional special events."
    },
    {
        timelineIcon: <MovieIcon />,
        timelineName: "Dream It Conventions",
        timelineDescription: "Volunteer Translator & Convention Coordinator",
        timelineDate: "2021 - Present"
    },
    {
        timelineIcon: <SchoolIcon />,
        timelineName: "ESCP Business School",
        timelineDescription: "MSc Marketing & Creativity",
        timelineDate: "2022 - Present (expected graduation 2023)",
        timelineExpand: "Note: Program ranked #5 worldwide (QS 2022 Global Master in Marketing Rankings). <br/> <br/> <b> ** Currently searching for a work placement position in London, UK from Dec. 2022 to July 2023.</b> ** "
    },
];

// Complete Timeline Function
const CompleteTimeline = () => {

    const classes = useStyles()

    return (
        <>     

            <div className={classes.container} >
                <Grid container>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Box className="timelineContainer">
                            <Timeline align="alternate">
                                {timelineItems.map((lsItem, key) => (
                                    <TimelineItem key={key}>
                                        <TimelineOppositeContent>
                                            <Typography className="timelineItemDates">
                                                {lsItem.timelineDate}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator >
                                            <TimelineDot className="timelineItemDot">
                                                {lsItem.timelineIcon}
                                            </TimelineDot>
                                            <TimelineConnector/>
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <TimelineCard lsItem={lsItem} />
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </Box>
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </div>
        </>
    )
};

export default CompleteTimeline;